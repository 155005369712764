<template>
  <div class="upload_file">
    

    <img v-if="this.data.image" :src="this.$store.state.options.img_path + this.data.image" alt="">
    <div class="input__wrapper" >
   <input type="file" @change="handleFileUpload(), submitFile()" :id="data.id" class="input">
   <label :for="data.id" class="input__file-button">
      <span class="input__file-icon-wrapper"></span>
      <span class="input__file-button-text">Выберите файл</span>
   </label>
    </div>
    <div   class="del input__file-button" @click="DeletedFile()">
        <span class="input__file-icon-wrapper"></span>
        <span class="input__file-button-text" >Удалить</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'upload_file',
  data(){
    return {
        files:'',
    }
  },
  methods:{
    ...mapActions({
            GET_QUOTE_API:'GET_QUOTE_API',
    }),
    submitFile(){
        let formData = new FormData()
        formData.append('image', this.files)
        formData.append('quote', this.data.quote)
        this.axios({
            method: 'PUT',
            url: `${this.$store.state.api}UploadImg/${this.data.id}/`,
            data:formData,
            }).then(response =>{
                console.log(response.data)
                this.GET_QUOTE_API(this.$route.query.categories)
            })
            .catch(function(error){
                console.log(error)         
        }) 
        //     this.axios.post('https://nimirum.ru/PDO_BD/ADD_IMAGE.php',
        //     formData,               
        //     {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     },
        // ).then(
        //     this.update_data()             
        //     )
        //     .catch(function(){
        //     alert('ERROR')
        // })       
    },
    handleFileUpload(){
        this.files = event.target.files[0]
        console.log(this.files)

    },
    DeletedFile(){
        let data = {quote:this.data.quote, image:''}
        this.axios({

            method: 'PUT',
            url: `${this.$store.state.api}getQuote/${this.data.id}/`,
            data:data,
            }).then(response =>{
                console.log(response.data)
                this.GET_QUOTE_API(this.$route.query.categories)
            })
            .catch(function(error){
                console.log(error)         
        }) 
    }
  },
  props: {
    data: Object,
    active:Boolean,
  }
}
</script>

<style scoped>
img {
    max-width:255px;
}
.input{
    display:none;
}
.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #121127;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
</style>
