import axios from 'axios'
import options from '../options'
export default{
	modules: {
	    options,
  	},
	state:{
		notifications:{},
	},
	mutations:{
	SET_NOTIFICCATIONS(state, d){
      state.notifications = d
    },
    ADD_NOTIFICCATIONS(state, data){
	    axios({
	        method:'POST',
	        url:`${options.state.api}notifications/`,
	        data:data,
	    }).then(resp => {
	    	console.log(resp)
	    	this.dispatch('GET_NOTIFICCATIONS_API');
	    })

    },
    DEL_NOTIFICCATIONS(state, data){
      axios({
        method:'DELETE',
        url:`${options.state.api}notifications/${data.id}/`,
        data:data,
      }).then(resp => {
      	console.log(resp)
      	this.dispatch('GET_NOTIFICCATIONS_API');
      })      
    },
    PUT_NOTIFICCATIONS(state, data){
      axios({
        method:'PUT',
        url:`${options.state.api}notifications/${data.id}/`,
        data:data,
      }).then(resp => {
      	console.log(resp)
      	this.dispatch('GET_NOTIFICCATIONS_API');
      })      
    }
	},
	actions:{
	GET_NOTIFICCATIONS_API({commit, state}) {
      axios({
        method:'GET',
        url:`${options.state.api}notifications/`
      }).then(m => {commit('SET_NOTIFICCATIONS', m.data)})        
    },
	},
	getters:{
	GET_API_NOTIFICCATIONS(state){
      return state.notifications
    },
	}
}