import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/bootstrap-grid.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import _  from 'lodash'
import VueLodash from 'vue-lodash'
import CKEditor from '@ckeditor/ckeditor5-vue';
createApp(App).use(store).use( CKEditor ).use(router).use(VueAxios, axios).mount('#app')
