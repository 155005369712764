import axios from 'axios'
import options from '../options'

export default{
	modules: {
	    options,
  	},
	state:{
		deleted:[],
		select_all:false,
    	selectDel:false,
	},
	mutations:{
		ADD_TO_BASKET(state, data){
			let post_data = state.deleted.filter(item => item.id == data.id )
  			post_data = post_data.map(item => item.id)

			if (post_data != data.id){
				state.deleted.push(data)					
			} else {
				let i = ''
				state.deleted.forEach((item, index) =>  item.id == data.id ? i = index : console.log('error'))
				state.deleted.splice(i, 1)
				console.log(i)	
			}
			// if (state.select_all == true){
  
  	// 		}
		},
		ADD_ALL_BASKET(state, data){
			state.deleted = data
		},
		REMOVE_ALL_BASKET(state){
			state.deleted = []
		}
	},
	actions:{

	},
	getters:{
		GET_BASKET(state){
			return state.deleted
		}
	}
}