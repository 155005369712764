<template id="cart">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-3">
				<div class="row">
					<div class="col-md-12"><h1 class="tittle">Настройки</h1></div>
						<div class="col-md-12">
							<a class="btn" @click="this.$router.push({path:'/marketing'})">Создать рекламное предложение</a>
						</div>
						<div class="col-md-12">
							<a class="btn" @click="show_category = !show_category, show_option = true, show_link = true">Категории</a>
						</div>
						<div class="col-md-12">
							<a class="btn" @click="show_option = !show_option, show_category = true, show_link = true">Настройки показов</a>
						</div>
						<div class="col-md-12">
							<a class="btn" @click="show_link = !show_link, show_category = true, show_option = true">Импорт и ссылки</a>
						</div>
						<!-- <div class="col-md-12">
							<a class="btn" @click="deleted_categories()">Удалить раздел</a>
						</div> -->



			<div class="col-md-12" :class="{box_hide:show_category, box:show_category}">
				<h1 class="mt"></h1>
				<h2 class="tittle">Категории</h2>
				<a @click="copy_category()" class="btn">Копировать категорию</a>


				<h2>Название нового раздела</h2>
				<input class="text_field" type="text" v-model="add_categories.category">
				<a class="btn" @click='add_new_category()'>Добавить</a>
			</div>


			<div class="col-md-12" :class="{box_hide:show_option, box:!show_option}">
				<div class="row">

			<div class="col-md-12">
				<h1 class="mt"></h1>
				<h2 class="tittle">Настройка показов</h2>
				<h2>Показы:<span v-for="(chapterInfo, index) of this.GET_CHAPTER">{{chapterInfo.showing}}</span></h2>
				<input type="text" v-model="add_categories.showing" class="text_field">
				
			</div>

			<div class="col-md-12">
				<h2>Переодичность дней:<span v-for="(chapterInfo, index) of this.GET_CHAPTER">{{chapterInfo.date_interval}}</span></h2>
				<input type="text"  v-model="add_categories.date_interval" class="text_field">
			</div>

			<div class="col-md-12">
				<a @click="UPDATE_CATEGORIES()" class="btn">Сохранить</a>
			</div>
			</div>
			</div>

			<div class="col-md-12" :class="{box_hide:show_link, box:!show_link}">
			<div class="row">
			<div class="col-md-12">
				<h1 class="mt"></h1>
				<h2 class="tittle">Кнопки и навигация</h2>
			</div>
			<div class="col-md-12">
				<a class="btn" @click="toggle = !toggle">Импорт</a>
				<div :class="{box:toggle, box_hide:!toggle}">
				<p class="form_app_text">Введите текст через &</p>
				<textarea class="form_app" v-model="import_input" >
				</textarea>
				<a class="btn btn_down" @click="addQuote()">Загрузить</a>	
				</div>
			</div>
			<div class="col-md-12">
				<a class="btn" @click="getLinks()">Создать ссылку</a>
				<textarea class="links">{{this.links}}</textarea>
			</div>
			<div class="col-md-12">
				<a class="btn" @click="getLinks(), QrToggle = !QrToggle">Генератор QR</a>
				<QrcodeVue class="box_center" :class="{box:QrToggle, box_hide:!QrToggle}" 
				:value='this.links'
				:size='200'
				level="H"
				/>
			</div>
			</div>
			</div>
				</div>
			</div>
			

			
			<!--<div class="col-md-6">
				<input type="date" v-model="this.date"/>
				<input type="date" @change="get_date()" v-model="this.show_date"/>

			</div> -->	
			<div class="col-md-9">
				<div class="row">
					<div class="col-md-12">
				<h1 class="tittle">Список цитат</h1>
			</div>
<!-- 			<table class="table_line">
					<tr>
						<th>
						<a class="a_checkbox" type="checkbox">
							<input type="checkbox" @click="this.$store.state.select_all = !this.$store.state.select_all, this.$store.state.selectDel = !this.$store.state.selectDel, selectedAll()">Выбрать все</a>
						</th>
						<th>Описание</th>
						<th>Картинка</th>
						<th>Видео</th>
						<th>Реклама</th>
					</tr> 
					<tr v-for="(data, index) of UPDATE_QUOTE" :key="index">
						<td @click='updateQuote = index' @dblclick='updateQuote = -1'>
							<input class="checkbox_inside" type="checkbox" ref="checkbox" :checked="select_all" :value="data.id" @change="selectedDel(data)">
						</td>
						<td @click='updateQuote = index' @dblclick='updateQuote = -1'>
						<p class="quote_text">{{data.quote}} <textarea @change="putQuote(data)" v-if="updateQuote === index" class="putQuote" type="text" v-model="data.quote"> </textarea> </p>
						</td>
						<td @click='updateQuote = index' @dblclick='updateQuote = -1'>
							<upload_file :data='data'/>
						</td>
						<td @click='updateQuote = index' @dblclick='updateQuote = -1'>
							<a class="btn_video" v-if="data.video_link && updateQuote != index">Видео добавлено</a>
							<div class="video" v-if="updateQuote === index">
							<h2>Добавить видео</h2>
								<div v-html='data.video_link'>									
								</div>
								<textarea @change="putQuote(data)" v-if="updateQuote === index" class="putVideo" type="text" v-model="data.video_link"> </textarea>
							</div>
						</td>
						<td>
					        <select v-model="data.marketing" @change="putMarketing(data, $event)" class="selection_tab">
						        <template v-for="(m, index) of GET_API_MARKETING" :key="index">
						        <option  :value="m.id">
						        {{m.marketing_description}}
						    	</option>
						        </template>
					      	</select>
						</td>

					</tr> 
				</table> -->




				<div id="user" class="user">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<a class="a_checkbox" type="checkbox">
					<input type="checkbox" @click="this.$store.state.select_all = !this.$store.state.select_all, this.$store.state.selectDel = !this.$store.state.selectDel, selectedAll()">Выбрать все</a>
				</div>
				<div class="col-md-4" v-for="(data, index) of UPDATE_QUOTE" :key="index">
					<div class="box_shadow" @click='updateQuote = index' @dblclick='updateQuote = -1'>
					<div class="text">
						<div class="text_apper flex border_bottom">
							<input class="checkbox_inside" type="checkbox" ref="checkbox" :checked="select_all" :value="data.id" @change="selectedDel(data)">
							<p class="">Текст</p>
						</div>
						
						<!-- <ckeditor @blur="putQuote(data)" :editor="editor" v-model="data.quote" :config="editorConfig"></ckeditor> -->
						<p class="quote_text">{{data.quote}} <textarea @change="putQuote(data)" v-if="updateQuote === index" class="putQuote" type="text" v-model="data.quote"> </textarea> </p>
					</div>
					<div class="text">
						<div class="text_apper flex border_bottom">
							<p class="">Описание</p>
						</div>
						<!-- <p class="quote_text" v-html="data.description"></p> -->
						<ckeditor @blur="putQuote(data)" :editor="editor" v-model="data.description" :config="editorConfig"></ckeditor>
						<!-- <textarea  v-if="updateQuote === index" class="putQuote" type="text" v-model="data.description"> </textarea> --> 
					</div>
					<div class="kartinka">
						<div class="text_apper justify_content">
							<p class="">Картинка</p>
<!-- 							<a class="acept_btn">Загрузить картинку</a> -->
						</div>
						<div>
							<upload_file :data='data'/>
						</div>
					</div>
					<div class="video">
						<div class="text_apper justify_content">
							<p class="">Видео</p>
							<a class="btn_video" v-if="data.video_link && updateQuote != index">Видео добавлено</a>
						</div>
						<div>	
							<div class="video" v-if="updateQuote === index">
							<h2>Добавить видео</h2>
								<div v-html='data.video_link'>									
								</div>
								<textarea @change="putQuote(data)" v-if="updateQuote === index" class="putVideo" type="text" v-model="data.video_link"> </textarea>
							</div>
						</div>
					</div>
					<div class="">
						<div class="text_apper justify_content">
							<p class="">Реклама</p>
						</div>
						<div class="">
							<select v-model="data.marketing" @change="putMarketing(data, $event)" class="selection_tab">
						        <template v-for="(m, index) of GET_API_MARKETING" :key="index">
						        <option  :value="m.id">
						        {{m.marketing_description}}
						    	</option>
						        </template>
					    </select>
						</div>
					</div>
					</div>
				</div>

<!-- 				<div class="col-md-4">
					<div class="box_shadow">
					<div class="text">
						<div class="text_apper flex">
							<input type="checkbox">
							<p class="">Текст</p>
						</div>
					</div>
					<div class="video">
						<div class="text_apper justify_content">
							<p class="">Видео</p>
							<a class="acept_btn">Загрузить видео</a>
						</div>
					</div>
					<div class="kartinka">
						<div class="text_apper justify_content">
							<p class="">Картинка</p>
							<a class="acept_btn">Загрузить картинку</a>
						</div>
					</div>
					<div class="reklama">
						<div class="text_apper justify_content">
							<p class="">Реклама</p>
						</div>
					</div>
					</div>
				</div> -->

			</div>
		</div>
	</div>




<!-- 			<div class="col-md-12 quote_box_shadow" v-for="(data, index) of UPDATE_QUOTE" :key="index">
				<div class="a_checkbox_all flex">
				
				
				</div>
				<div class="quote_box">
					<div class="flex" >
						
						<p class="quote_id">{{index + 1}}</p>
						

					</div>

					

					
				</div>

			</div> -->
			
			<div class="col-md-4 offset-md-4">
<!-- 			<div class="center">
			 <a class="btn delit_btn" @click="deletedQuote()">Удалить цитаты</a>
			 </div> -->
			 <div class="center">
			 <a class="btn delit_btn" @click="showMore()">Показать еще</a>
			 </div>
			 <div class="center">
			 <a class="btn delit_btn" @click="this.$router.push({path:'/basket', query:{categories:this.$route.query.categories}})">Корзина {{this.$store.state.basket.length}}</a>
			 </div>
			<paginate 
				v-model="page"
				:page-count="this.$store.state.pageCount"
				:click-handler="paginationItems"
				:prev-text="'Назад'"
				:next-text="'Далее'"
				:container-class="'pagination'"
				:page-class="'page-item'"
			/>
			</div>
				</div>
			</div>
			

		</div>
	</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TextEditor from './admin/textEditor.vue'
import paginate from '../components/paginate.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import upload_file from '@/components/upload_file.vue';
	export default {
		name:'cart',
		components:{
			QrcodeVue,
			paginate,
			upload_file,
			TextEditor,
		},
		data(){
			return {
				toggle:false,
				show_category:true,
				show_option:true,
				show_link:true,
				import_input:'',
				QrToggle:false,
				links:'',
				category: this.$route.query.categories || '',
				check:[],
				add_categories:{category:'', app:this.$route.params.id, showing:'1', date_interval:'1'},
				deleted:[],
				updateQuote:-1,
				pageSize:6,
      			pageCount: this.$store.state.pageCount,
      			items:[],
      			page:1,
      			newCategory:'',
      			basketData:{},
      			marketing_id:'',
      			editor: ClassicEditor,
	            editorConfig: {
	                // The configuration of the editor.
	            }
			}
		},
  		mounted(){
  			// console.log(this.$route.query.categories)
  			this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  			this.GET_MARKETING_API()
  		},
  		methods:{
  			...mapMutations({
  				ADD_NEW_CATEGORIES:'ADD_NEW_CATEGORIES',
  				UPDATE_CATEGORIES:'UPDATE_CATEGORIES',
  				COPY_CATEGORY:'COPY_CATEGORY',
  				itemPaginate:'itemPaginate',
  				ADD_TO_BASKET:'ADD_TO_BASKET',
  				ADD_ALL_BASKET:'ADD_ALL_BASKET',
  				REMOVE_ALL_BASKET:'REMOVE_ALL_BASKET',
  			}),
  			...mapActions({
  				GET_CATEGORIES_API:'GET_CATEGORIES_API',
  				GET_QUOTE_API:'GET_QUOTE_API',
  				GET_MARKETING_API:'GET_MARKETING_API',
		  	}),
			paginationItems(page){
		        this.page = page     
		        this.$store.state.items = this.$store.state.paginationMassive[this.page -1] || this.$store.state.paginationMassive[0]
      		},
      		showMore(){ 
      			this.$store.state.pageSize += 10
      			this.itemPaginate()   			
      			// console.log(this.$store.state.pageSize, '- PageSize')
      		},
  			add_new_category(){
  			this.ADD_NEW_CATEGORIES(this.add_categories)
  			console.log(this.add_categories)
  			},
  			UPDATE_CATEGORIES(){
  				// let data = []
  				// data.push(this.add_categories, this.$route.query.categories)
  				// this.UPDATE_CATEGORIES(data)
  				// this.GET_CATEGORIES_API()
          let cats = this.$store.state.api_categories.find(i => i.id == this.$route.query.categories);
          console.log(cats, 'CATSCATSCATSCATS')
          cats.date_interval = this.add_categories.date_interval;
          cats.showing = this.add_categories.showing;
          console.log(cats, 'CATSCATSCATSCATS')

  				this.axios({
		          method: 'PUT',
		            url: `${this.$store.state.api}categories/${this.$route.query.categories}/`,
		            data:cats,
		        }).then(response =>{
		            this.GET_CATEGORIES_API() 
		        })
		        .catch(function(error){
		            console.log(error)         
		        })
  			},
  			getLinks(){
  			this.links = 'https://frontend.zitatnik.ru/' + '?categories=' + this.$route.query.categories
  			},
  			selectedAll(){
  				// console.log(this.$store.state.selectDel)
  				if(this.$store.state.selectDel == true){
  					this.ADD_ALL_BASKET(this.UPDATE_QUOTE) 
  				} else {
  					this.REMOVE_ALL_BASKET()
  					console.log(this.$store.state.deleted)
  				}
  			},
  			selectedDel(data){
  					this.ADD_TO_BASKET(data)
  			},
  			deletedQuote(){
  				let category = this.$route.query.categories
	  			this.axios({
			    	method: 'POST',
			    	url: `${this.$store.state.api}DeleteQuote/`,
			    	data:this.$store.state.deleted,
			    	}).then(response =>{console.log(response.data)
			    		this.GET_QUOTE_API(category)
			   		    this.$store.state.deleted = [] 
			    	})
			    	.catch(function(error){
			            console.log(error)         
			    	})
	  		},
  			addQuote(){
  			let new_data = this.import_input.split('&').filter(element => element != ' ' && element != '')
  			let data = []
  			let category = this.$route.query.categories
  			for (let i = 0; i < new_data.length; i++){
  				data.push({quote:new_data[i], category:category, image:'', description:''})
  			}
  			console.log(data)

  			this.axios({
		        method: 'POST',
		        url: `${this.$store.state.api}copyQuote/`,
		        data:data,
		    }).then(response =>{
		            this.GET_QUOTE_API(category)
		            this.import_input = ''
		    })
		    .catch(function(error){
		            console.log(error)         
		    })
  		},
  		putQuote(data){
  			console.log(data)
  			let PostData = {
  				'quote':data.quote,
  				'video_link':data.video_link,
  				'description':data.description,
  				'image':data.image,
  			}
  			this.axios({
		        method: 'PUT',
		        url: `${this.$store.state.api}getQuote/${data.id}/`,
		        data:PostData,
		    }).then(response =>{
		            this.GET_QUOTE_API(this.$route.query.categories)
		            this.import_input = ''
		    })
		    .catch(function(error){
		            console.log(error)         
		    })
  		},
  		putMarketing(data, event){
  			console.log(event.target.value)
  			console.log(data)
  			let PostData = {
  				'quote':data.quote,
  				'video_link':data.video_link,
  				'marketing':event.target.value,
  				'image':data.image,
  			}
  			this.axios({
		        method: 'PUT',
		        url: `${this.$store.state.api}getQuote/${data.id}/`,
		        data:PostData,
		    }).then(response =>{
		            this.GET_QUOTE_API(this.category)
		            this.import_input = ''
		    })
		    .catch(function(error){
		            console.log(error)         
		    })
  		},
  		copy_category(){
		let category = this.$route.query.categories

  			// this.COPY_CATEGORY(category)

      let data = this.$store.state.api_categories.filter(item => {return item.id == category})
      let quote = this.$store.state.api_quote.filter(item => {return item.category == category})
      let arr = data

      let new_id = this.$store.state.api_categories.length - 1
      let keys = Object.keys(this.$store.state.api_categories)
      let object = this.$store.state.api_categories[keys[new_id]]
      console.log(object.id)

      // console.log(quote)
      // console.log(data)
      // console.log(new_id, 'new-id')
      

      const newArray = arr.map(item =>
        item.id == category
          ? { ...item, category: item.category + ' - copy', }
          : item
      )
      let categories = newArray[0]
    

    this.$store.state.api_categories.push(categories)

    this.axios({
      method:'POST',
      url:`${this.$store.state.api}AddCategories/`,
      data:categories
    }).then(info =>{

     const newQuotes = quote.map(item =>
        item.category == category
          ? { ...item, category:info.data.category}
          : item
      )
     console.log(newQuotes, 'PostQuotes')
     console.log(info.data.category, 'rquest category')
     this.axios({
	    	method: 'POST',
	    	url: `${this.$store.state.api}CopyQuoteImage/`,
	    	data:newQuotes,
	    }).then(info => console.log(info))
	    .catch(function(error){
	                console.log(error)         
        });
        
 	}
    )

  		},

  		deleted_categories(){
	      let category = this.$route.query.categories
	      this.axios({
	          method: 'DELETE',
	            url: `${this.$store.state.api}categories/${category}/`,
	        }).then(response =>{
	            this.GET_CATEGORIES_API()
	        })
	        .catch(function(error){
	            console.log(error)         
	        })  
	    }
  		},
  		computed:{
  			...mapGetters([
  				'GET_API_QUOTE',
  				'GET_API_CATEGORIES',
  				'GET_API_MARKETING'
  			]),
  			GET_CHAPTER:function(){
  			return this.GET_API_CATEGORIES.filter(item => { return item.id == this.$route.query.categories})
  		},
	  		UPDATE_QUOTE:function(){
	  			return this.$store.state.items
	  		},
	  		select_all:function(){
	  			return this.$store.state.select_all
	  		}
  		},
  		props:{

  		}
	}
</script>

<style scoped>
	a {
		cursor:pointer;
	}
	.box_shadow {
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
		margin:10px 0;
		padding:10px;
	}
	.justify_content {
		justify-content: space-between;
	}
	/*******видео*******/
	.acept_btn:hover {
		color:#121127;
		background-color:#fff;
		transition: all .3s ease;
	}
	.acept_btn {
		height:30px;
		padding:6px;
		background-color:#121127;
		margin-top:4px;
		border:1px solid #26A69A;
		border-radius:5px;
		margin-left:10px;
		color:#fff;
		float:right;
		border:solid 1px #121127;
	}
	.video_img {
		width:100%;
		height:auto;
	}
	.texteria_url {
		max-width: 100%;
	    margin: 5px 0;
	    width: 330px;
	    height: 100px;
	}
	/*******видео*******/
	/*******текст*******/
	.text_apper p {
		margin:10px 0;
	}
	.text_apper input {
		margin: 11px 5px 11px 0;
	    width: 14px;
	    height: 14px;
	}
	.text_apper {
		border-bottom:solid 1px #C0C0C0;
		display:flex;
	}
	/*******текст*******/

	/*******Всплывающий список*******/
	.spisok {
		margin:0;
		text-align:left;
		list-style-type: none;
		width:250px;
		padding:0;
	}
	.spisok_2 li {
		text-align:left;
		padding:5px 0 5px 16px;
		background-color:#F8F8F8;
		width:250px;
		cursor:pointer;
	}
	.spisok_2 li:last-child {
		border-radius:0 0 5px 5px ;
	}
	.spisok_1 li {
		width:250px;
		background-color:#F8F8F8;
		padding:8px 0 8px 16px;
		border:solid 1px #8A8A8A;
		border-radius:5px;
	}
	.spisok_2 li:hover {
		background-color:#1B4AF0;
		color:#fff;
	}
	/*******Всплывающий список*******/

	.table_line{
		width:100%;
	}
	.table_line td:first-child{
		width:5%;
	}
	.table_line td{
		width:15%;
	}
	.table_line th {
		text-align:left;
	}
	.video{
		margin-top:5%;
		padding-bottom:5%;
	}
	.putVideo{
		width:100%;
	}
	.checkbox_inside {
		margin-top:17px;
	}
	.a_checkbox_all {
		text-align:left;
		font-size:20px;
		padding:0 0 5px 34px;
	}
	.a_checkbox {
		margin: 10px 10px 0 0;
	}
	.pagination {
		background:#000;
	}
	.putQuote{
		position: absolute;
    	left: 0;
    	font-size: 16px;
    	outline: none;
    	border: none;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	color:#2c3e50;
    	font-size:16px;
    	font-family: Avenir, Helvetica, Arial, sans-serif;
    	padding:0;
	}
	.mt{
		margin-top:15px;
		margin-bottom:15px;
		border-top:1px solid #121127;
	}
	.box_center {
		margin:0 auto;
		margin-top:10px;
	}
	.box{
		display:block;
	}
	.box_hide{
		display:none;
	}
	.tittle {
		margin:3% 0 3% 0%;
		text-decoration:underline;
		font-weight:300;
	}
	.flex {
		display:flex;
	}
	.text_field{
		outline: none !important;
   		width: 90%;
    	height: 27px;
    	border-radius: 5px;
    	font-size: 15px;
    	border: solid 1px #D0D0D0;
    	color: #121127;
    	opacity: 70%;
    	padding: 18px 10px;
	}
	.text_field:hover {
		border: solid 1px #A9A9A9;
	}
	.text_field:focus {
		border: solid 1px #444BD3;
	}
	.btn {
		background: #fff;
		color:#121127;
		width:80%;
		padding:15px 0px 15px 0px;
		line-height: 10px;
		display:block;
		margin:0 auto;
		font-size: 18px;
		cursor:pointer;
		margin-top: 5%;
		border:solid #D0D0D0 1px;
		border-radius:5px;
		transition:all 300ms ease;
	}
	.btn:hover {
		background: #F0F0F0;
		color:#121127;
		transition:all 300ms ease;
	}
	.btn_down{
		width:50%;
	}
	.form_app {
		display:block;
		width:60%;
		margin:0 auto;
		min-height:150px;
		margin-top:10px;
		border-radius:5px;
		outline: none !important;
		font-size: 16px;
	}
	.form_app_text{
		font-size:16px;
		text-align:left;
		display:block;
		width:60%;
		margin:0 auto;
		margin-top: 10px;
	}
	.quote_box{
		box-shadow: rgb(18 17 39 / 8%) 0px 0px 50px 10px;
		border-radius:5px;
		display:block;
		padding:0px 0px 0px 34px;
	}
	.quote_id{
		text-align:left;
		font-size:16px;
		font-weight: bold;
		padding-right:5px;
	}
	.quote_text{
		text-align:left;
		width:50%;
		position:relative;
		height:50px;
		overflow:hidden;
		width:100%;
	}
	.quote_box_shadow{
		padding-bottom:1%;
	}
	.links {
		width: 60%;
    	height: 80px;
    	border:none;
    	outline:none;
    	margin-top:2%;
	}
	.display_none {
		display:none;
	}
	.btn_video {
		cursor:pointer;
	    background: #fff;
    	color: #121127;
    	width: 200px;
    	height: 39px;
    	padding: 9px;
    	border-radius: 10px;
    	margin-top: 6px;
    	border:1px solid #D0D0D0;
    	transition: all 300ms ease;
    	margin-left:20%;
	}
	.btn_video:hover {
		background:#F0F0F0;
		color:#121127;
		transition: all 300ms ease;
	}
	.delit_btn {
		width:200px;
		text-align:center;
		margin-bottom:10px;
	}
	/*******Всплывающий список*******/
.selection_tab {
  font-size:14px;
  width:100%;
  height:40px;
  padding-left:12px;
  outline:none;
  border:solid 1px rgba(18, 17, 39, 0.12);
  appearance: none;
  border-radius:5px;
}
.selection_tab option {
  font-size:14px;
  line-height: 1.5;
  border:solid 1px rgba(18, 17, 39, 0.12);
  outline:none;
  border-radius:5px;  
}
/*******Всплывающий список*******/
</style>