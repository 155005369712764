<template>
  <div class="basket">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h2 class="tittle">Категория {{basketCategory[0].category}}</h2>
  				<div class="col-md-6 offset-md-3">
					<a class="btn" @click="deleted_categories(), this.$router.go(-1)">Удалить Категорию</a>
				</div>
  			</div>
			<div class="col-md-12">
				<h2 class="tittle">Список цитат в корзине</h2>
				<table class="table_line">
					<tr><th>Убрать</th><th>id</th><th>Цитата</th></tr> <!--ряд с ячейками заголовков-->
					<tr v-for ="(basket , index) of this.GET_BASKET" :key='index'>
						<td>
						<input class="checkbox_inside" type="checkbox" ref="checkbox" :value="basket.id" @change="selectedDel(basket)">
						</td>
						<td>{{basket.id}}</td>
						<td>{{basket.quote}}</td>
						<!-- <td>{{basket.category.category}}</td> -->
					</tr> <!--ряд с ячейками тела таблицы-->
				</table>
				<div class="row">
					<div class="col-md-6 offset-md-3">
						<a class="btn" @click="deletedQuote()">Удалить цитаты</a>
					</div>
				</div>
			</div>	
  		</div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
export default {
  name: 'basket',
  props:{
  	basket:Object,
  	category:String, 	
  },
  mounted(){
  	    this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  },
  beforeRouteUpdate(to, from, next) {
  		this.GET_CATEGORIES_API()
        next()
    // обрабатываем изменение параметров маршрута...
    // не забываем вызвать next()
    },
  methods:{
	...mapMutations({
		ADD_TO_BASKET:'ADD_TO_BASKET',
		REMOVE_ALL_BASKET:'REMOVE_ALL_BASKET',
	}),
	...mapActions({
		GET_CATEGORIES_API:'GET_CATEGORIES_API',
		GET_QUOTE_API:'GET_QUOTE_API'
	}),
	selectedAll(){
		console.log(this.$store.state.selectDel)

		if(this.$store.state.selectDel == true){

			// let array = this.UPDATE_QUOTE.map( item => item.id)

			for (let i=0; i < array.length; i++){
				this.$store.state.deleted.push({'id':array[i]})
			}
			console.log(this.$store.state.deleted)
		} else {
			this.$store.state.deleted = []
			console.log(this.$store.state.deleted)
		}
	},
	selectedDel(data){
			console.log(data)
			this.ADD_TO_BASKET(data)

	},
	deletedQuote(){
		let array = this.GET_BASKET.map(item => item.id)
		let del_data = []
		for (let i=0; i < array.length; i++){
				del_data.push({'id':array[i]})
			}
			console.log(del_data)
		let category = this.$route.query.categories
		this.axios({
    	method: 'POST',
    	url: `${this.$store.state.api}DeleteQuote/`,
    	data:del_data,
    	}).then(response =>{console.log(response.data)
    		this.GET_QUOTE_API(category)
   		    this.REMOVE_ALL_BASKET()
    	})
    	.catch(function(error){
            console.log(error)         
    	})
	},
	deleted_categories(){
	      let category = this.$route.query.categories
	      this.axios({
	          method: 'DELETE',
	            url: `${this.$store.state.api}categories/${category}/`,
	        }).then(response =>{
	            this.GET_CATEGORIES_API()
	        })
	        .catch(function(error){
	            console.log(error)         
	        })  
	    },
  },
  computed:{
  	...mapGetters([
		'GET_BASKET',
		'GET_API_CATEGORIES'
  	]),
  	basketCategory:function(){
  		return this.GET_API_CATEGORIES.filter(item => item.id == this.$route.query.categories)
  	}
  }
}
</script>

<style scoped>
.table_line {
	width:100%;
	font-size:20px;
}
.tittle {
	margin:3% 0 3% 0%;
	text-decoration:underline;
	font-weight:300;
	font-size:34px;
	text-align:center;
}
.btn {
	background: #fff;
	color:#121127;
	width:80%;
	padding:15px 0px 15px 0px;
	line-height: 10px;
	display:block;
	margin:0 auto;
	font-size: 18px;
	cursor:pointer;
	margin-top: 5%;
	border:solid #D0D0D0 1px;
	border-radius:5px;
	transition:all 300ms ease;
}
.btn:hover {
	background: #F0F0F0;
	color:#121127;
	transition:all 300ms ease;
}
</style>
