import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import adminView from '../views/admin/admin.vue'
import marketing from '../views/admin/marketing.vue'
import basket from '../views/admin/basket.vue'
import notifications from '../views/admin/notifications.vue'
import authorization from '../views/authorization.vue'
import userView from '../views/user/user.vue'
import userTimeView from '../views/user/userUserTime.vue'
import axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'user',
    component: userView,
    query:{categories:''}
  },
  {
    path: '/quote',
    name: 'userTimeView',
    component: userTimeView,
    query:{categories:''}
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: authorization,
  },
  {
    path: '/admin/:id',
    name: 'admin',
    component: adminView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/adminHome',
    name: 'adminHome',
    component: HomeView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: marketing,
    meta:{auth:true},
  },
  {
    path: '/basket',
    name: 'basket',
    component: basket,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: notifications,
    meta:{auth:true},
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
   // const Oauth = to.matched.some(record => record.meta.auth)

    //if (Oauth && !store.mutations.SESSION()){
    //    next('/about')
   // } else {
   //     next()
    //}


    if (to.matched.some(record => record.meta.auth)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу логина
    axios({
            method: 'GET',
            headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `https://zitatnik.ru/api/AutSession/`,
        }).then(response =>{
            console.log(response)
            if (response.data == true) {
                next()
            }
        }).catch(function(error){
            console.log(error) 
            next({
              path: '/authorization',
            })        
        })
    
  } else {
    next() // всегда так или иначе нужно вызвать next()!
  }
})

export default router
