import axios from 'axios'

export default{
	state:{
		api:'https://zitatnik.ru/api/',
		img_path:'https://zitatnik.ru/media/',
		path:{categories:'categories/', updateCategories:'updateCategories/', getQuote:'getQuote/', marketing:'marketing/', Application:'Application/'}
	},
	mutations:{
	},
	actions:{

	},
	getters:{
	}
}