<template>
  <div class="basket_page">
  	<div class="container">
  		<div class="col-md-12">
  			<div class="col-md-12">
  				<basket/>
  			</div>
  		</div>
  	</div>
  </div>





</template>

<script>
import basket from '@/components/admin/basket.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'basket_page',
  components: {
  	basket,
  },
    data(){
    return {
    }
  },
  mounted(){
  },
  methods:{
  	...mapMutations({
  	}),
  	...mapActions({
  	}),
  },
  computed:{
  	...mapGetters([
  	]),
  }
}
</script>

<style>

</style>


