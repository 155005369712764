import { createStore } from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import options from './options'
import Home from './admin/Home'
import basket from './admin/basket'
import marketing from './admin/marketing'
import notifications from './admin/notifications'
export default createStore({
  modules: {
    Home,
    basket,
    marketing,
    options,
    notifications,
  },
  state: {
    api:'https://zitatnik.ru/api/',
    image_path:'https://zitatnik.ru/media/',
    api_categories:[],
    api_quote:[],
    marketing:[],
    categories:'',
    USER_QUOTE:{quote:'Нажмите на выбор цитаты', video_link:''},
    update_user_session:false,
    pageSize:80,
    pageCount: 0,
    items:[],
    page:1,
    paginationMassive:null,
    new_categories:null,
  },
  mutations: {
    itemPaginate(state){ 
        state.paginationMassive = state.api_quote  
        state.pageCount = Math.ceil(state.paginationMassive.length / state.pageSize)
        state.paginationMassive = _.chunk(state.paginationMassive, state.pageSize)
        state.items = state.paginationMassive[state.page -1] || state.paginationMassive[0]
        // console.log(state.items, '- ItemsPaginate')
      },
    SET_CATEGORIES(state, categories){
      console.log(categories)
      state.api_categories = categories
    },
    SET_QUOTE(state, quote){
      console.log(quote)
      state.api_quote = quote
    },
    ADD_NEW_CATEGORIES(state, categories){
      state.api_categories.push(categories)
      axios({
          method: 'POST',
            url: `${options.state.api}AddCategories/`,
            data:categories,
        }).then(response =>{
            console.log(response.data)
            this.dispatch('GET_CATEGORIES_API', categories.app);  
        })
        .catch(function(error){
            console.log(error)         
        })      
    },
    COPY_CATEGORY(state, category){
      
      let data = state.api_categories.filter(item => {return item.id == category})
      let quote = state.api_quote.filter(item => {return item.category == category})
      let arr = data

      let new_id = state.api_categories.length - 1
      let keys = Object.keys(state.api_categories)
      let object = state.api_categories[keys[new_id]]
      // console.log(object.id)

      // console.log(quote)
      // console.log(data)
      // console.log(new_id, 'new-id')
      

      const newArray = arr.map(item =>
        item.id == category
          ? { ...item, category: item.category + ' - copy', }
          : item
      )
      let categories = newArray[0]
      
      let newQuotes = quote.map(item =>
        item.category == category
          ? { ...item, category:object.id + 1}
          : item
      )
      console.log(newQuotes)

      state.api_categories.push(categories)

      // for (let i = 0; i < newQuotes.length; i++){
      //   state.api_quote.push(newQuotes[i])
      // }


    
    console.log(object.id + 1, '-obj-id')
    console.log(newQuotes)
    console.log(newArray)

    axios({
      method:'POST',
      url:`${options.state.api}AddCategories/`,
      data:categories
    }).then(info => 
    axios({
      method: 'POST',
            url: `${options.state.api}GetLastCategories/`,
            data:'',
    }).then(info =>  console.log(info.data.category),)

        //     axios({
    //   method: 'POST',
    //         url: `${options.state.api}copyQuote/`,
    //         data:newQuotes,
    // }).then(info => console.log(info))
    // .catch(function(error){
    //             console.log(error)         
    //     })

    .catch(function(error){
                console.log(error)         
        })


    )
    },

    UPDATE_STORE(state, data){
      //console.log(data.category)
      let id = 0

      if(typeof data == 'undefined'){

      } else {

      // localStorage.setItem(data.category, data.showing)
      let FrontSession = JSON.parse(localStorage.getItem("FrontSession"))

      if (FrontSession == null){
        let FrontSession = []
        FrontSession.push(data)
        localStorage.setItem('FrontSession', JSON.stringify(FrontSession));
      } else {

        FrontSession = JSON.parse(localStorage.getItem("FrontSession"))

        id = FrontSession.filter(item => {return item.category == data.category})
        id = id.map(item => {return item.category})[0]
        // console.log(id)
        // console.log(data.category)                
        if (id == data.category) {
          if (data.update_date == true){
          FrontSession = FrontSession.map(item =>
          item.category == data.category
            ? { ...item, showing:data.showing, date:data.date}
            : item
          )
          localStorage.setItem('FrontSession', JSON.stringify(FrontSession)); 
        }
        } else {
          FrontSession.push(data)
          localStorage.setItem('FrontSession', JSON.stringify(FrontSession)); 
        }

      }


      }
    },
    USER_GET_QUOTE(state, category){
      let count = ''
      axios({
      method:'POST',
      url:`${options.state.api}getUserQuote/`,
      data:{
              'category':category,
            }
    }).then(info => 
    {

      let data = info.data
      let FrontSession = JSON.parse(localStorage.getItem("FrontSession")) 
      let detail = FrontSession.filter(item => {return item.category == data.category})
      let showing = detail.map(item => {return item.showing})[0]

      if (showing <= 0){
        state.alert_quote = true
      }
      if (showing > 0){
        showing = showing - 1
        console.log(showing)
        console.log(detail) 
        FrontSession = FrontSession.map(item =>
        item.category == data.category
          ? { ...item, showing:showing}
          : item
        )
        console.log(FrontSession)
        localStorage.setItem('FrontSession', JSON.stringify(FrontSession));
        state.USER_QUOTE = info.data, localStorage.setItem('quote', JSON.stringify(info.data))
      }
    })
    },
    UPDATE_STORE_USER_DATE(state, data){
      let id = 0
      let day = (data.date_interval * 1000)
      let FrontSessionDate = JSON.parse(localStorage.getItem("FrontSessionDate"))
      let today = new Date().toLocaleDateString()
      let last_date = new Date(new Date().getTime() + 24 * 60 * 60 * day).toLocaleDateString()
      

      if (FrontSessionDate == null){
        let UserData = data
        Object.assign(UserData,{last_date:last_date})
        UserData.date = today
        let FrontSessionDate = []

        FrontSessionDate.push(data)
        localStorage.setItem('FrontSessionDate', JSON.stringify(FrontSessionDate))
      } else {
        id = FrontSessionDate.filter(item => {return item.category == data.category})
        id = id.map(item => {return item.category})[0]
        if(id == data.category){
          //Проверка на обновление даты
          if(state.update_user_session == true){
          FrontSessionDate = FrontSessionDate.map(item =>
          item.category == data.category
            ? { ...item, date:today, last_date:last_date, showing:data.showing}
            : item
          )
          localStorage.setItem('FrontSessionDate', JSON.stringify(FrontSessionDate))
          }

        } else {
          let UserData = data
          UserData.date = today
          Object.assign(UserData,{last_date:last_date})
          FrontSessionDate.push(data)
          localStorage.setItem('FrontSessionDate', JSON.stringify(FrontSessionDate))
        }

      }

    },
    USER_GET_FRONT_QUOTE(state, category){
      let count = ''
      axios({
      method:'POST',
      url:`${options.state.api}getUserQuote/`,
      data:{
              'category':category,
            }
    }).then(info => 
    {
      let data = info.data
      let FrontSessionDate = JSON.parse(localStorage.getItem("FrontSessionDate")) 
      let detail = FrontSessionDate.filter(item => {return item.category == data.category})
      let showing = detail.map(item => {return item.showing})[0]
      let last_date = detail.map(item => {return item.last_date})[0]
      let date = detail.map(item => {return item.date})[0]
      let today = new Date().toLocaleDateString()
      state.update_user_session = false

      if (date > last_date) {
        state.update_user_session = true
        let array = {category:data.category, user_history:10}
        console.log('nohello')
        this.dispatch('CREATE_FRONT_SESSION', array);
      }
      if (last_date >= date){
        FrontSessionDate = FrontSessionDate.map(item =>
        item.category == data.category
          ? { ...item, date:today}
          : item
        )
        if (detail[0].showing == 0){
          state.alert_quote = true
        } else {
          showing = detail[0].showing -1
          FrontSessionDate = FrontSessionDate.map(item =>
          item.category == data.category
          ? { ...item, date:today, showing:showing}
          : item
        )
          state.USER_QUOTE = info.data, localStorage.setItem('quote', JSON.stringify(info.data))
        }
        localStorage.setItem('FrontSessionDate', JSON.stringify(FrontSessionDate))
      } 
    })
    },
  },
  actions: {
    GET_CATEGORIES_API({commit, state}, appID) {
      axios({
        method:'GET',
        url:`${options.state.api}categories/?app=${appID}`
      }).then(categories => {commit('SET_CATEGORIES', categories.data)})        
    },
    GET_QUOTE_API({commit, state}, categories) {
      axios({
        method:'GET',
        url:`${options.state.api}getQuote/?category=${categories}`
      }).then(quote => {commit('SET_QUOTE', quote.data), this.commit('itemPaginate')})        
    },
    CREATE_SESSION({commit, state}, ar){
      axios({
      method:'PUT',
      url:`${options.state.api}creatSessionUser/`,
      data:ar,
    }).then(info => {commit('UPDATE_STORE', info.data), console.log(info.data)})
    },
    CREATE_FRONT_SESSION({commit, state}, ar){
      axios({
      method:'POST',
      url:`${options.state.api}creatFrontSessionUser/`,
      data:ar,
    }).then(info => {commit('UPDATE_STORE_USER_DATE', info.data)})
    },  
  },
  getters: {

    GET_API_CATEGORIES(state){
      return state.api_categories
    },
    GET_API_QUOTE(state){
      return state.api_quote
    },
    GET_USER_QUOTE(state){
      return state.USER_QUOTE
    }

  }
})
