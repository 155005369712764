<template>

  <div class="marketing">
    <div class="container">
 <!--      <h3 class="apper_h3">Cоздать новое проедложение</h3> -->
<!--       <div class="row">
        <div class="col-md-4 offset-md-4">
          <p class="text_apper_input">Описание</p>
          <input type="text" v-model="post_data.quotes_limit" class="text_field">
        </div>
        <div class="col-md-4 offset-md-4">
          <a class="btn" @click="addNewMark()">Добавить</a>
        </div>
      </div> -->
      <h3 class="apper_h3">Настройки</h3>
      <div class="row">
        <div class="col-md-12">
          <table class="table_line">
            <tr><th>id</th><th>Описание</th></tr> <!--ряд с ячейками заголовков-->

            <tr v-for="(m, index) of GET_API_NOTIFICCATIONS" :key="index">
              <td>{{m.id}}</td>
              <td>{{m.quotes_limit}} <input type="text" class="model_market" @change="putMark(m)" v-model="m.quotes_limit"></td>
              <td><i @click="delMark(m)" class="delit_icon"></i></td>
            </tr> <!--ряд с ячейками тела таблицы-->
          </table>
        </div>
      </div>
    </div>
  </div>





</template>

<script>

import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: '',
  components: {
  },
    data(){
    return {
        post_data:{quotes_limit:''}
    }
  },
  mounted(){
    this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
    this.GET_NOTIFICCATIONS_API()
    console.log(this.GET_NOTIFICCATIONS_API)

  },
  methods:{
    ...mapMutations({
      ADD_NOTIFICCATIONS:'ADD_NOTIFICCATIONS',
      DEL_NOTIFICCATIONS:'DEL_NOTIFICCATIONS',
      PUT_NOTIFICCATIONS:'PUT_NOTIFICCATIONS'
    }),
    ...mapActions({
      GET_NOTIFICCATIONS_API:'GET_NOTIFICCATIONS_API',
    }),
    addNewMark(){
      this.ADD_NOTIFICCATIONS(this.post_data)
      console.log(this.post_data)
    },
    delMark(data){
      this.DEL_NOTIFICCATIONS(data)
    },
    putMark(data){
      this.PUT_NOTIFICCATIONS(data)
    }
  },
  computed:{
    ...mapGetters([
      'GET_API_NOTIFICCATIONS',
    ]),
  }
}
</script>

<style scoped>

</style>


