<template>

  <div class="marketing">
    <div class="container">
      <h3 class="apper_h3">Cоздать новое проедложение</h3>
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <p class="text_apper_input">Описание</p>
          <input type="text" v-model="post_data.marketing_description" class="text_field">
        </div>
        <div class="col-md-4 offset-md-4">
          <p class="text_apper_input">Ссылка</p>
          <input type="text" v-model="post_data.marketing_link" class="text_field">
        </div>
        <div class="col-md-4 offset-md-4">
          <a class="btn" @click="addNewMark()">Добавить</a>
        </div>
      </div>
      <h3 class="apper_h3">Рекламные предложения</h3>
      <div class="row">
        <div class="col-md-12">
          <table class="table_line">
            <tr><th>id</th><th>Описание</th> <th>Ссылка</th></tr> <!--ряд с ячейками заголовков-->

            <tr v-for="(m, index) of GET_API_MARKETING" :key="index">
              <td>{{m.id}}</td>
              <td>{{m.marketing_description}} <input type="text" class="model_market" @change="putMark(m)" v-model="m.marketing_description"></td>
              <td>{{m.marketing_link}} <input type="text" class="model_market" @change="putMark(m)"  v-model="m.marketing_link"></td>
              <td><i @click="delMark(m)" class="delit_icon"></i></td>
            </tr> <!--ряд с ячейками тела таблицы-->
          </table>
        </div>
      </div>
    </div>
  </div>





</template>

<script>

import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: '',
  components: {
  },
    data(){
    return {
        post_data:{marketing_description:'', marketing_link:''}
    }
  },
  mounted(){
    this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
    this.GET_MARKETING_API()
    console.log(this.GET_API_MARKETING)

  },
  methods:{
  	...mapMutations({
      ADD_MARKETING:'ADD_MARKETING',
      DEL_MARKETING:'DEL_MARKETING',
      PUT_MARKETING:'PUT_MARKETING'
  	}),
  	...mapActions({
      GET_MARKETING_API:'GET_MARKETING_API',
  	}),
    addNewMark(){
      this.ADD_MARKETING(this.post_data)
    },
    delMark(data){
      this.DEL_MARKETING(data)
    },
    putMark(data){
      this.PUT_MARKETING(data)
    }
  },
  computed:{
  	...mapGetters([
      'GET_API_MARKETING',
  	]),
  }
}
</script>

<style scoped>
  /*******Новые предложения*******/
.delit_icon {
  display: inline-block;
  cursor:pointer;
  width:20px;
  height:20px;
  background-image:url('@/assets/ico/marketing/delite.svg');
    background-position: center;
    background-repeat:no-repeat;
    position: relative;
    margin:0 auto;
    padding-top:2px;
}
.table_line {
  width:100%;
  font-size:20px;
}
.table_line td{
  position:relative;
  font-size:18px;
}
.text_apper_input {
  margin-bottom:5px;
  text-align:left;
  font-size:15px;
}
.model_market{
  position:absolute;
  width:100%;
  height:auto;
  top:0;
  left:0;
  border:none;
  outline:none;
  text-align:center;
  font-size:18px;
}
.btn {
    background: #fff;
    color:#121127;
    width:80%;
    padding:13px 0px 13px 0px;
    line-height: 10px;
    display:block;
    margin:0 auto;
    font-size: 18px;
    cursor:pointer;
    margin-top: 5%;
    border:solid #D0D0D0 1px;
    border-radius:5px;
    transition:all 300ms ease;
  }
  .btn:hover {
    background: #F0F0F0;
    color:#121127;
    transition:all 300ms ease;
  }
  .btn_down{
    width:50%;
  }
.text_field{
  outline: none !important;
    width: 100%;
    height: 27px;
    border-radius: 5px;
    font-size: 15px;
    border: solid 1px #D0D0D0;
    color: #121127;
    opacity: 70%;
    padding: 18px 10px;
}
.text_field:hover {
  border: solid 1px #A9A9A9;
}
.text_field:focus {
  border: solid 1px #444BD3;
}
.apper_h3 {
  font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
/*******Новые предложения*******/


</style>


