import axios from 'axios'
import options from '../options'

export default{
	modules: {
	    options,
  	},
	state:{
		api:'http://127.0.0.1:8000/api/',
		application:{},
	},
	mutations:{
		SET_APPLICATION(state, application){
	      console.log(application)
	      state.application = application
	    },
	   	POST_APPLICATION_API(state, app){
     		axios({
	          method: 'POST',
	            url: `${options.state.api}Application/`,
	            data:app,
	        }).then(response =>{
	             this.dispatch('GET_APPLICATION_API');
	        })
	        .catch(function(error){
	            console.log(error)         
	        }) 
	    },
	    PUT_APPLICATION_API(state, app){
	    	console.log(app)
		    axios({
	          method: 'PUT',
	            url: `${options.state.api}Application/${app.id}/`,
	            data:app,
	        }).then(response =>{
	             this.dispatch('GET_APPLICATION_API');
	        })
	        .catch(function(error){
	            console.log(error)         
	        })       
	    },
	    DELETE_APPLICATION_API(state, app){
		    axios({
	          method: 'DELETE',
	            url: `${options.state.api}Application/${app.id}/`,
	            data:app,
	        }).then(response =>{
	             this.dispatch('GET_APPLICATION_API');
	        })
	        .catch(function(error){
	            console.log(error)         
	        })       
	    },
	},
	actions:{
		GET_APPLICATION_API({commit, state}) {
	      axios({
	        method:'GET',
	        url:`${options.state.api}Application/`
	      }).then(categories => {commit('SET_APPLICATION', categories.data)})        
	    },
	},
	getters:{
		GET_APPLICATION(state){
	      return state.application
	    },
	}
}