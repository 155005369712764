<template>

<div :class="{nav_class_open:!nav_open, nav_class_close : nav_open}">
        <a @click="nav_open = !nav_open"><img class="ico_toggle" src="@/assets/ico/nav/close.svg" alt=""></a>
        <ul class="nav_list" >
          <li v-for="(data, index) of GET_API_CATEGORIES" :key="data.id" @click="getCategories(data.id)"> <i><img class="ico_nav" src="@/assets/ico/nav/house-door.svg" alt=""></i> <span @dblclick="updateCategories = index" class="nav_text">{{data.category}} <textarea v-if="updateCategories == index" v-model="data.category"  class="update_categories" @change="update_categories(data)" type="text"></textarea></span></li>
        </ul>
      </div>

  <div class="content">


    <admin/>

  </div>





</template>

<script>

import admin from '@/components/admin.vue'

import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'Home',
  components: {
    admin,
  },
    data(){
    return {
      nav_open:true,
      updateCategories:-1,
      nav_class_open:'',
      nav_class_close:'',
      categories:this.$route.query.categories || '1',
    }
  },
  mounted(){
    this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  	this.GET_CATEGORIES_API(this.$route.params.id)
    this.getCategories(this.categories)
    console.log(this.GET_API_CATEGORIES)

  },
  methods:{
  	...mapMutations({
      REMOVE_ALL_BASKET:'REMOVE_ALL_BASKET',
  	}),
  	...mapActions({
  		GET_CATEGORIES_API:'GET_CATEGORIES_API',
  		GET_QUOTE_API:'GET_QUOTE_API',
  	}),
  	getCategories(categories){
      this.$router.push({query:{categories:categories}})
      console.log(categories)
  		this.GET_QUOTE_API(categories)
      this.$store.state.select_all = false
      this.$store.state.selectDel = false
      this.REMOVE_ALL_BASKET()
  	},
    update_categories(categories){
      console.log(categories, )
      this.updateCategories = -1
      this.axios({
          method: 'PUT',
            url: `${this.$store.state.api}categories/${categories.id}/`,
            data:categories,
        }).then(response =>{
            console.log(response.data)  
        })
        .catch(function(error){
            console.log(error)         
        })  
    }
  },
  computed:{
  	...mapGetters([
  		'GET_API_CATEGORIES',
  	]),
  }
}
</script>

<style>
  .update_categories{
    position: absolute;
    background:#121127;
    color: #fff;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 16px;
    outline: none;
    border:none;
  }
	.nav_class_open {
      width: 150px;
      height: 100%;
      position: fixed;
      top: 0;
      background: #121127;
      transition: all 500ms ease;
      z-index: 999;
  }
  .nav_class_close {
    transition: all 500ms ease;
      height: 100%;
      position: fixed;
      top: 0;
      background: #121127;
      width: 48px;
      z-index: 999;
  }
  .ico_toggle{
    height:26px;
    width: 26px;
    margin-top: 10px;
  }
  .ico_nav {
    height:26px;
    width:26px;
    margin-left: -5px;
    position: relative;
    top: -8px;
  }
  .nav_class_open .ico_toggle {
    transform: rotate(0deg);
    transition: all 500ms ease;
  }
  .nav_class_close .ico_toggle {
    transform: rotate(180deg);
    transition: all 500ms ease;
  }
  .nav_class_close .nav_text{
    opacity: 0;
    position: absolute;
    display:none;
  }
  .nav_class_open .nav_text{
    opacity: 1;
    position: absolute;
    left: 40px;
  }  
  .menu_img{

  }
  .menu_text{

  }
  .nav_list{
    list-style-type: none;
    padding:0px 0px 0px 15px;
    position: relative;
  }
  .nav_list li{
    text-align: left;
    margin-top:10px;
    font-size: 17px;
    color:#FFFFFF;
    cursor: pointer;

  }
  .nav_list li:first-child {
    margin-top:0px;
  }
  .nav_class_close + .content{
    width:90%;
    height: auto;
    overflow:hidden;
    transform: translateX(48px);
    transition: all 500ms ease;
  }
  .nav_class_open + .content{
    transition: all 500ms ease;
    width:90%;
    height: auto;
    overflow:hidden;
    transform: translateX(150px);
  }
</style>


