<template>
  <div class="Application">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-12">
  				<h3 class="title_lv_2">База цитатник</h3>
  				<h1 class="title_lv_1">Выберите  приложение</h1>
  			</div>
  			<div class="col-md-4" v-for="(app, index) of GET_APPLICATION" :key="index">
  				<div class="cart_box">
  				<div class="cart">
  					<i class="ico"></i>
  					<p class="cart_tittle">{{app.name}} <input type="text" @change="updateApplication(app)" class="update_tittle" v-model="app.name"></p> 					
  					<p class="cart_description">{{app.description}} <input type="text" @change="updateApplication(app)" class="update_description" v-model="app.description"></p>
  					<button @click="deleteApplication(app)">Удалить</button>
  					<a @click='goToAdmin(app.id)'>Перейти</a>	
  				</div>
  			</div>
  			</div>
  			<div class="col-md-4" @click='postApplication()'>
  				<div class="box_add">
  					<i class="ico_add"></i>
  				</div>
  			</div>
  		</div>
  	</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'Application',
  data(){
  	return {
  		newApplication:{name:'Новое приложение', description:'Описание приложения'},
  	}
  },
  mounted(){
    this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  	this.GET_APPLICATION_API()

  },
  components: {

  },
  methods:{
	...mapMutations({
		PUT_APPLICATION_API:'PUT_APPLICATION_API',
		POST_APPLICATION_API:'POST_APPLICATION_API',
		DELETE_APPLICATION_API:'DELETE_APPLICATION_API',

  	}),
  	...mapActions({
  		GET_APPLICATION_API:'GET_APPLICATION_API',
  	}),
  	updateApplication(app){
  		this.PUT_APPLICATION_API(app)
  	},
  	postApplication(){
  		this.POST_APPLICATION_API(this.newApplication)
  	},
  	deleteApplication(app){

  		this.DELETE_APPLICATION_API(app)
  	},
  	goToAdmin(id){
  		console.log(id)
  		this.$router.push({ path: `/admin/${id}`, query: {}})
  	}
  },
  computed:{
	...mapGetters([
  		'GET_APPLICATION',
  	]),
  },
}
</script>

<style scoped>
.Application{
	margin-top:5%;
}
.title_lv_2{
	color: #444BD3;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
}
.title_lv_1{
	color:#121127;
	text-align:left;
	font-weight: bold;
    font-size: 32px;
    margin-bottom:10%;
}
.cart_box {

	cursor:pointer;
}
.cart{
	padding:10%;
	text-align:left;
    text-align: left;
    box-shadow: -10px 20px 30px rgb(18 17 39 / 8%);
    border-radius: 5%;
    padding:20% 10% 20% 10%;
}
.ico{
	width: 36px;
    height: 36px;
    display: block;
    background: linear-gradient(140deg, rgba(222,222,222,.9), rgba(222,222,222,0) 90.71%);
    transform: rotate(45deg);
    border-radius: 4.5px;
    margin:10% 0 24px 0;
}
.cart_tittle{
	margin-top: 42px;
    font-size: 18px;
	font-weight:bold;
	color:#121127;
	margin-bottom:24px;
	position:relative;
}
.cart_description{
	margin-top:5%;
	padding-bottom:5%;
	color:#1211278F;
	font-size:16px;
	position:relative;
}
.box_add{
	display: block;
    height: 100%;
    width: 100%;
    border-radius: 5%;
    box-shadow: -10px 20px 30px rgb(18 17 39 / 8%);
    position:relative;
	cursor:pointer;
}
.ico_add{
	display: block;
    width: 22px;
    height: 3px;
    background: #121127;
    margin: auto;
    position: absolute;
    border-radius:5px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.ico_add:before{
	content: '';
	border-radius:5px;
    width: 22px;
    height: 3px;
    background: #121127;
    margin: auto;
    position: absolute;
    transform: rotate(90deg);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.update_tittle{
	font-size: 18px;
    font-weight: bold;
    color: #121127;
    border:none;
    position:absolute;
    left:0;
    top:0;
    outline:none;
}
.update_tittle:focus{
	border-bottom:2px solid #121127;
}
.update_description{
	color:#1211278F;
	font-size:16px;
    border:none;
    position:absolute;
    left:0;
    top:0;
    outline:none;
}
.update_description:focus{
	border-bottom:2px solid #1211278F;
}
</style> 