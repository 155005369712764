import axios from 'axios'
import options from '../options'
export default{
	modules: {
	    options,
  	},
	state:{
		marketing:{},
	},
	mutations:{
	SET_MARKETING(state, m){
      state.marketing = m
    },
    ADD_MARKETING(state, data){
	    axios({
	        method:'POST',
	        url:`${options.state.api}marketing/`,
	        data:data,
	    }).then(resp => {
	    	console.log(resp)
	    	this.dispatch('GET_MARKETING_API');
	    })

    },
    DEL_MARKETING(state, data){
      axios({
        method:'DELETE',
        url:`${options.state.api}marketing/${data.id}/`,
        data:data,
      }).then(resp => {
      	console.log(resp)
      	this.dispatch('GET_MARKETING_API');
      })      
    },
    PUT_MARKETING(state, data){
      axios({
        method:'PUT',
        url:`${options.state.api}marketing/${data.id}/`,
        data:data,
      }).then(resp => {
      	console.log(resp)
      	this.dispatch('GET_MARKETING_API');
      })      
    }
	},
	actions:{
	GET_MARKETING_API({commit, state}) {
      axios({
        method:'GET',
        url:`${options.state.api}marketing/`
      }).then(m => {commit('SET_MARKETING', m.data)})        
    },
	},
	getters:{
	GET_API_MARKETING(state){
      return state.marketing
    },
	}
}